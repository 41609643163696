import ButtonLink from '@uikit/components/ButtonLink/ButtonLink';
import type { ButtonLinkProps } from '@uikit/components/ButtonLink/interfaces';
import MeinHGasButton from '@uikit/components/MeinHGasButton/MeinHGasButton';
import React from 'react';

const MeinHGasButtonLink = (props: ButtonLinkProps) => {
  return <ButtonLink {...props} ButtonComponent={MeinHGasButton} />;
};

export default MeinHGasButtonLink;
