import * as Yup from 'yup';

import { street, zipcode, city } from '../../../schemas';

export default Yup.object({
  city,
  streetName: street,
  streetNumber: Yup.string().required('Wir benötigen eine Haus-Nummer'),
  zipCode: zipcode,
  zipCodeFound: Yup.mixed().oneOf(
    [true],
    'Diese PLZ liegt außerhalb unseres Gebiets'
  ),
  streetNameSelected: Yup.mixed().oneOf(
    [true],
    'Bitte wählen Sie eine Straße aus den Vorschlägen'
  ),
});
