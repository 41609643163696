import ContentsToReact from '@/components/ContentsToReact/ContentsToReact';
import GenericModals from '@/components/GenericModals/GenericModals';
import Page from '@/components/Page/Page';
import { extractContents } from '@/helpers/extractContents';
import extractPageMetaFromRouteData from '@/helpers/extractPageMetaFromRouteData';
import useBodyClass from '@/hooks/useBodyClass/useBodyClass';
import { useInitialPageDataLayer } from '@/hooks/useInitialPageDataLayer/useInitialPageDataLayer';
import type { PageModel } from '@/models/page';
import BackToTopButton from '@uikit/components/BackToTopButton/BackToTopButton';
import Layout from '@uikit/components/Layout/Layout';
import { LayoutVariant } from '@uikit/components/Layout/interfaces';
import MeinHGasFooter from '@uikit/components/MeinHGasFooter/MeinHGasFooter';
import MeinHGasModal from '@uikit/components/MeinHGasModal/MeinHGasModal';
import { CmsComponentsContext } from '../CmsComponentsContext';
import cmsComponents from './MeinHGasDefaultPage.cmsComponents';
import type { DefaultPageProps } from './interfaces';

const MeinHGasDefaultPage = (
  pageData: PageModel<DefaultPageProps>
): JSX.Element => {
  const meta = extractPageMetaFromRouteData(pageData);

  const {
    extractedContents: [noticeBarArea],
    restContents,
  } = extractContents(
    ['notice-bar-area', 'mein-h-gas-footer', 'footer'],
    pageData.contents
  );

  useBodyClass(['mein-h-gas']);

  useInitialPageDataLayer();

  return (
    <CmsComponentsContext.Provider value={cmsComponents}>
      {noticeBarArea && <ContentsToReact contents={noticeBarArea} />}

      <Layout variant={LayoutVariant.Default}>
        <Page meta={meta}>
          {restContents && <ContentsToReact contents={restContents} />}
        </Page>

        <BackToTopButton />

        <MeinHGasFooter />
      </Layout>

      <GenericModals modals={pageData.modals} modalComponent={MeinHGasModal} />
    </CmsComponentsContext.Provider>
  );
};

export default MeinHGasDefaultPage;
