import Container from '@uikit/components/Container/Container';
import MeinHGasFooter from '@uikit/components/MeinHGasFooter/MeinHGasFooter';
import MeinHGasHeader from '@uikit/components/MeinHGasHeader/MeinHGasHeader';
import MeinHGasHeadline from '@uikit/components/MeinHGasHeadline/MeinHGasHeadline';
import MeinHGasStageBoxText from '@uikit/components/MeinHGasStageBoxText/MeinHGasStageBoxText';
import Section from '@uikit/components/Section/Section';
import SectionVisual from '@uikit/components/SectionVisual/SectionVisual';
import TwoColumnContainer from '@uikit/components/TwoColumnContainer/TwoColumnContainer';
import HeroArea from '@uikit/composites/HeroArea/HeroArea';
import MeinHGasButtonLink from '@uikit/composites/MeinHGasButtonLink/MeinHGasButtonLink';
import MeinHGasChangeoverDatesForm from '@uikit/composites/MeinHGasChangeoverDatesForm/MeinHGasChangeoverDatesForm';
import SmallHeroArea from '@uikit/composites/SmallHeroArea/SmallHeroArea';
import MeinHGasModal from '@uikit/components/MeinHGasModal/MeinHGasModal';
import React from 'react';
import IframeSection from '@uikit/components/IframeSection/IframeSection';
import Forms from '@/components/Forms/Forms';

export const CmsComponentsHgas = {
  'mein-h-gas-footer': MeinHGasFooter,
  'mein-h-gas-header': MeinHGasHeader,
  'mein-h-gas-stage-box-text': MeinHGasStageBoxText,
  'mein-h-gas-section': Section,
  'mein-h-gas-container': Container,
  'mein-h-gas-stage-small': SmallHeroArea,
  'mein-h-gas-stage': HeroArea,
  'mein-h-gas-stage-area': React.Fragment,
  'mein-h-gas-section-visual': SectionVisual,
  'mein-h-gas-headline': MeinHGasHeadline,
  'mein-h-gas-button': MeinHGasButtonLink,
  'mein-h-gas-two-column-container': TwoColumnContainer,
  'mein-h-gas-changeover-dates-form': MeinHGasChangeoverDatesForm,
  'mein-h-gas-modal': MeinHGasModal,
  'mein-h-gas-iframe-section': IframeSection,
  'mein-h-gas-forms': Forms,
};
