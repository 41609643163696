import React from 'react';
import { HeadlineColor } from '../Headline/consts';
import Headline from '../Headline/Headline';
import type { MeinHGasHeadlineProps } from './interfaces';
import styles from './MeinHGasHeadline.module.scss';

const MeinHGasHeadline = ({
  children,
  headlineColor = HeadlineColor.Gradient,
  ...props
}: MeinHGasHeadlineProps) => {
  return (
    <Headline headlineColor={headlineColor} className={styles.base} {...props}>
      {children}
    </Headline>
  );
};

export default MeinHGasHeadline;
