import { StageBoxProps } from '@uikit/components/StageBox/interfaces';
import clsx from 'clsx';
import React from 'react';
import StageBox from '../StageBox/StageBox';
import styles from './MeinHGasStageBox.module.scss';

const MeinHGasStageBox = ({
  backgroundType = 'gradient',
  bgClassName,
  children,
}: StageBoxProps) => {
  const className = clsx(
    backgroundType === 'gradient' ? styles.base : undefined,
    bgClassName
  );

  return <StageBox bgClassName={className}>{children}</StageBox>;
};

export default MeinHGasStageBox;
