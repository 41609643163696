import { ClickableAction } from '../Clickable/consts';
import LegacyHeader from '../LegacyHeader/LegacyHeader.wrapper';
import { LegacyHeaderVariant } from '../LegacyHeader/consts';
import type { MeinHGasHeaderProps } from './types';

const MeinHGasHeader = (props: MeinHGasHeaderProps) => {
  return (
    <LegacyHeader
      actionType={ClickableAction.None}
      variant={LegacyHeaderVariant.MeinHGas}
      {...props}
    />
  );
};

export default MeinHGasHeader;
