import MeinHGasButton from '@uikit/components/MeinHGasButton/MeinHGasButton';
import MeinHGasStage from '@uikit/components/MeinHGasStageBox/MeinHGasStageBox';
import type { MeinHGasStageBoxTextProps } from '@uikit/components/MeinHGasStageBoxText/types';
import StageBoxText from '@uikit/components/StageBoxText/StageBoxText';

const MeinHGasStageBoxText = (props: MeinHGasStageBoxTextProps) => {
  return (
    <StageBoxText
      {...props}
      StageBoxComponent={MeinHGasStage}
      ButtonComponent={MeinHGasButton}
    />
  );
};

export default MeinHGasStageBoxText;
