import Button from '@uikit/components/Button/Button';
import React from 'react';
import type { MeinHGasButtonProps } from './interfaces';
import styles from './MeinHGasButton.module.scss';

const MeinHGasButton = (props: MeinHGasButtonProps) => {
  return <Button customAddonClass={styles.customAddon} {...props} />;
};

export default MeinHGasButton;
