import Background from '../Background/Background';
import NewFooter from '../NewFooter/NewFooter';
import ProjectLogo from '../ProjectLogo/ProjectLogo';
import { MeinHGasFooterProps } from './types';

const MeinHGasFooter = ({ skewOverlap }: MeinHGasFooterProps) => {
  return (
    <NewFooter
      backgroundSlot={<Background.Gradient gradient="meinHGas" />}
      copyrightText="enercity Netz GmbH"
      foundationSlot={
        <>
          <NewFooter.Link href="/presse">Presse</NewFooter.Link>
          <NewFooter.Link href="/kontakt">Kontakt</NewFooter.Link>
          <NewFooter.Link
            href="https://www.enercity-netz.de/impressum"
            rel="noreferrer"
            target="_blank"
          >
            Impressum
          </NewFooter.Link>
          <NewFooter.Link
            href="https://www.enercity-netz.de/datenschutz"
            rel="noreferrer"
            target="_blank"
          >
            Datenschutz
          </NewFooter.Link>
          <NewFooter.Link href="/seitenuebersicht">
            Seitenübersicht
          </NewFooter.Link>
        </>
      }
      logoSlot={<ProjectLogo height={80} project="eng" solidFill />}
      skewOverlap={skewOverlap}
    />
  );
};

export default MeinHGasFooter;
