import React from 'react';
import config, { endpoints } from '../../config';
import {
  ChangeoverDateFormState,
  ChangeoverDateResponseData,
} from './interfaces';

type Status = 'initial' | 'pending' | 'success' | 'fail';

const endpoint = `${config.api.baseUrl}${endpoints.getChangeoverDate}`;
const cache: Record<string, ChangeoverDateResponseData> = {};

const fetchWithCache = async (
  formState: ChangeoverDateFormState
): Promise<ChangeoverDateResponseData> => {
  const { streetName, streetNumber, city, zipCode, frcCaptchaSolution } =
    formState;
  const body = JSON.stringify({
    streetName,
    streetNumber,
    city,
    zipCode,
    frcCaptchaSolution,
  });
  if (cache[body]) {
    return cache[body];
  }

  const response = await fetch(endpoint, {
    method: 'POST',
    body,
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const responseData = await response.json();

  cache[body] = responseData;

  return responseData;
};

const useChangeoverDates = () => {
  const [data, setData] = React.useState<ChangeoverDateResponseData>({});
  const [status, setStatus] = React.useState<Status>('initial');

  const run = async (
    formState: ChangeoverDateFormState,
    onSuccess?: (responseData: ChangeoverDateResponseData) => void,
    onFailure?: (errorMessage: string) => void
  ) => {
    try {
      setStatus('pending');

      const responseData = await fetchWithCache(formState);

      setData(() => responseData);
      setStatus('success');
      onSuccess?.(responseData);
    } catch (error) {
      setStatus('fail');

      onFailure?.(
        typeof error === 'string' ? error : 'Bitte überprüfen Sie ihre Angaben'
      );
    }
  };

  return {
    run,
    data,
    setData,
    status,
    resetDate: () => {
      setStatus(() => 'initial');
      setData((data) => ({ ...data, date: undefined }));
    },
  };
};

export default useChangeoverDates;
