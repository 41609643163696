import useChangeoverDates from '@/hooks/useChangeoverDate/useChangeoverDate';
import { UseFormState } from '@/hooks/useForm/interfaces';
import useForm from '@/hooks/useForm/useForm';
import ButtonSubmit from '@uikit/components/ButtonSubmit/ButtonSubmit';
import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import FormField from '@uikit/components/FormField/FormField';
import { FormFieldElement } from '@uikit/components/FormField/interfaces';
import FormFieldRadioGroup from '@uikit/components/FormFieldRadioGroup/FormFieldRadioGroup';
import FormFieldSuggestion from '@uikit/components/FormFieldSuggestion/FormFieldSuggestion';
import { SuggestionOption } from '@uikit/components/FormFieldSuggestion/interfaces';
import FormRow from '@uikit/components/FormRow/FormRow';
import Icon from '@uikit/components/Icon/Icon';
import ResultInfoFormBox from '@uikit/components/ResultInfoFormBox/ResultInfoFormBox';
import React from 'react';
import schema from './schema';
import useFrcCaptcha from '@/hooks/useFrcCaptcha/useFrcCaptcha';

const initialState = {
  city: '',
  zipCode: '',
  zipCodeFound: false,
  streetName: '',
  streetNameSelected: false,
  streetNumber: '',
};

const MeinHGasChangeoverDatesForm = () => {
  const { getSolution, FrcCaptchaComponent } = useFrcCaptcha();
  const { run, data, setData, resetDate } = useChangeoverDates();

  const [buttonSubmitState, setButtonSubmitState] = React.useState(
    ButtonSubmitState.Initial
  );
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const submitButtonRef = React.useRef<HTMLButtonElement>(null);

  const onSubmit = async ({
    streetName,
    streetNumber,
    zipCode,
    city,
  }: UseFormState) => {
    submitButtonRef.current?.focus();

    if (buttonSubmitState === ButtonSubmitState.Success) {
      setButtonSubmitState(ButtonSubmitState.Initial);
      resetDate();
      return;
    }

    setButtonSubmitState(ButtonSubmitState.Pending);

    const frcCaptchaSolution = await getSolution();

    run(
      { streetName, streetNumber, zipCode, city, frcCaptchaSolution },
      () => {
        setButtonSubmitState(ButtonSubmitState.Success);
      },
      (errorMessage) => {
        setButtonSubmitState(ButtonSubmitState.Fail);
        setErrorMessage(errorMessage);
      }
    );
  };

  const {
    formState,
    getErrorByFieldName,
    handleBlur,
    handleChange,
    handleSubmit,
    isFormValid,
    isFieldValid,
    setFieldValue,
  } = useForm(onSubmit, schema, initialState);

  const handleZipCodeChange = ($event: React.ChangeEvent<FormFieldElement>) => {
    const zipCode = $event.target.value ?? '';

    if (zipCode.length === 5) {
      run(
        { zipCode },
        (data) => {
          setFieldValue('zipCodeFound', Boolean(data.cities?.length));
          if (data.cities?.length === 1) {
            const [city] = data.cities ?? [];
            setFieldValue('city', city);
          }
        },
        () => {
          setFieldValue('zipCodeFound', false);
        }
      );
    } else {
      setFieldValue('city', '');
      setData((data) => ({ ...data, cities: [] }));
    }
    handleChange($event);
  };

  const handleCityChange = ($event: React.ChangeEvent<FormFieldElement>) => {
    setData((state) => ({ ...state, streetNames: [] }));
    handleChange($event);
  };

  const handleChangeStreetName = (
    $event: React.ChangeEvent<FormFieldElement>
  ) => {
    const { zipCode, city } = formState;

    run({
      zipCode,
      city,
      streetName: $event.target.value ?? '',
    });

    handleChange($event);
  };

  const handleStreetNameSelect = (suggestionOption: SuggestionOption) => {
    setFieldValue('streetName', suggestionOption.value);
    setFieldValue('streetNameSelected', true);
    setData((state) => ({ ...state, streetNames: [] }));
  };

  const handleStreetNameBlur = ($event: React.FocusEvent<FormFieldElement>) => {
    const { zipCode, city, streetName } = formState;

    run(
      {
        zipCode,
        city,
        streetName,
      },
      (data) => {
        setFieldValue('streetNameSelected', data.streetName === streetName);
      },
      () => {
        setFieldValue('streetNameSelected', false);
      }
    );
    handleChange($event);
  };

  const handleButtonSubmitBlur = () => {
    if (errorMessage) {
      setButtonSubmitState(ButtonSubmitState.Initial);
      resetDate();
    }
  };

  const streetSuggestions: SuggestionOption[] =
    (isFieldValid('zipCodeFound') &&
      isFieldValid('city') &&
      formState.streetName.length &&
      data.streetNames?.map((streetName) => ({
        label: streetName,
        value: streetName,
      }))) ||
    [];

  return (
    <form onSubmit={handleSubmit}>
      {!data.date ? (
        <>
          <FormRow>
            <FormField
              label="Ihre Postleitzahl"
              name="zipCode"
              value={formState['zipCode']}
              onChange={handleZipCodeChange}
              onBlur={handleBlur}
              errorMessage={
                getErrorByFieldName('zipCode') ||
                getErrorByFieldName('zipCodeFound')
              }
            />
          </FormRow>
          {data.cities && data.cities.length > 1 && (
            <FormRow>
              <FormFieldRadioGroup
                label="Wählen Sie Ihren Ort aus:"
                name={'city'}
                items={data.cities.map((city) => ({
                  label: city,
                  value: city,
                }))}
                value={formState['city']}
                onChange={handleCityChange}
                errorMessage={getErrorByFieldName('city')}
              />
            </FormRow>
          )}
          <FormRow>
            <FormFieldSuggestion
              inputId="streetName"
              name="streetName"
              label="Straße"
              value={formState['streetName']}
              onChange={handleChangeStreetName}
              onBlur={handleStreetNameBlur}
              errorMessage={
                getErrorByFieldName('streetName') ||
                getErrorByFieldName('streetNameSelected')
              }
              options={streetSuggestions}
              nativeOptions={streetSuggestions}
              onSelectOption={handleStreetNameSelect}
              autoComplete="off"
              tabIndex={0}
              disableNativeSelect
            />
            <FormField
              name="streetNumber"
              label="Haus-Nr."
              value={formState['streetNumber']}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={getErrorByFieldName('streetNumber')}
            />
          </FormRow>
          <ButtonSubmit
            state={buttonSubmitState}
            stateMessages={{
              fail: 'Bitte überprüfen Sie Ihre Angaben',
              pending: 'den Termin suchen...',
            }}
            onBlur={handleButtonSubmitBlur}
            inactive={!isFormValid}
            buttonRef={submitButtonRef}
          >
            Umstelltermin anzeigen
          </ButtonSubmit>
        </>
      ) : (
        <div>
          <FormRow>
            <ResultInfoFormBox>
              <Icon variant={'user/calendar'} size="iconSize48" />
              <span>Ihr Umstelltermin ist der {data.date}</span>
            </ResultInfoFormBox>
          </FormRow>
          <br />
          <ButtonSubmit state={ButtonSubmitState.Initial}>
            Weiteren Umstelltermin ansehen
          </ButtonSubmit>
        </div>
      )}
      <FrcCaptchaComponent />
    </form>
  );
};

export default MeinHGasChangeoverDatesForm;
